import React from "react"
import * as styles from './Wysiwyg.module.css'

export function Wysiwyg ({ children }) {
  return (
    <article className={styles.wysiwyg}>
      { children }
    </article>
  )
}
