import React from "react"
import cx from 'classnames'

import * as styles from './Container.module.css'

export function ContainerLg({ children, layoutClassName = undefined }) {
  return <ContainerBase className={cx(styles.containerLg, layoutClassName)} {...{ children }} />
}

export function ContainerMd({ children, layoutClassName = undefined }) {
  return <ContainerBase className={cx(styles.containerMd, layoutClassName)} {...{ children }} />
}

export function ContainerSm({ children, layoutClassName = undefined }) {
  return <ContainerBase className={cx(styles.containerSm, layoutClassName)} {...{ children }} />
}

function ContainerBase({ className, children }) {
  return (
    <div className={cx(styles.componentBase, className)}>
      <div className={styles.inner}>{children}</div>
    </div>
  )
}
