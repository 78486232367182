import cx from 'classnames'
import * as React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import { HeadingSm } from '../heading/Heading'

import * as styles from './SiteFooter.module.css'

export function SiteFooter() {
  return (
    <footer className={styles.siteFooter} role="contentinfo">
      <div className={styles.top}>
        <div>
          <div className={styles.heading}>
            <HeadingSm h={3}>Contactgegevens</HeadingSm>
          </div>
          <address className={styles.address}>
            Gooisekust 74<br/>
            1274 JH Huizen
          </address>
          <p className={styles.contactLink}>
            <span className={cx("material-icons")}>call</span>
            <a href="tel:+31355242454">+31 (0)35 524 2454</a>
          </p>
          <p className={styles.contactLink}>
            <span className={cx("material-icons")}>email</span>
            <a href="mailto:fjlambooy@mondhygieniste-huizen.nl">fjlambooy@mondhygieniste-huizen.nl</a>
          </p>
        </div>

        <div>
          <div className={styles.heading}>
            <HeadingSm h={3}>Openingstijden</HeadingSm>
          </div>
          <table className={styles.table}>
            <tbody>
              <tr>
                <td>Maandagochtend:</td>
                <td>op speciaal verzoek</td>
              </tr>
              <tr>
                <td>Dinsdag:</td>
                <td>08:30 - 17:00 uur</td>
              </tr>
              <tr>
                <td>Woensdagochtend:</td>
                <td>08:30 - 12:00 uur</td>
              </tr>
              <tr>
                <td>Donderdag:</td>
                <td>08:30 - 17:00 uur</td>
              </tr>
              <tr>
                <td>Vrijdagochtend:</td>
                <td>op speciaal verzoek</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <div className={styles.heading}>
            <HeadingSm h={3}>Op de kaart</HeadingSm>
          </div>
          <a href="https://goo.gl/maps/8rronHCqnSpYBaxu7" title="Ga naar Google Maps" target="_blank" rel="noopener noreferrer" className={styles.map}>
            <StaticImage className={styles.image} src="../../images/map.jpg" alt="" />
          </a>
        </div>

      </div>

      <div className={styles.bottom}>
        <p className={styles.copyright}>© {new Date().getFullYear()} Mondhygieniste Huizen</p>
      </div>
    </footer>
  )
}
