// extracted by mini-css-extract-plugin
export var siteFooter = "SiteFooter-module--siteFooter--58NAG";
export var top = "SiteFooter-module--top--nxSsW";
export var bottom = "SiteFooter-module--bottom--7jdmq";
export var heading = "SiteFooter-module--heading--uKObE";
export var address = "SiteFooter-module--address--33Ggd";
export var contactLink = "SiteFooter-module--contactLink--2b5A7";
export var materialIcons = "SiteFooter-module--material-icons--xlXld";
export var table = "SiteFooter-module--table--qFnvZ";
export var map = "SiteFooter-module--map--M0l4r";
export var image = "SiteFooter-module--image--V6-HT";
export var copyright = "SiteFooter-module--copyright--dR7da";