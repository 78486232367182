import React from "react"
import { Helmet } from "react-helmet";

export function Layout({ title, desc, children }) {
  return (
    <div>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={desc} />
        <meta name="google-site-verification" content="6Zf_3yLNovwRrZP7jjNQN5nTW5i89f1gpYKOCduHTWQ" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <link rel="preload" href=".../fonts/AtypText-Light.woff2" as="fonts/woff2" crossOrigin="anonymous" type="font/woff2" />
        <link rel="preload" href=".../fonts/AtypText-Regular.woff2" as="fonts/woff2" crossOrigin="anonymous" type="font/woff2" />
        <link rel="preload" href=".../fonts/AtypText-Medium.woff2" as="fonts/woff2" crossOrigin="anonymous" type="font/woff2" />
        <link rel="preload" href=".../fonts/AtypText-Bold.woff2" as="fonts/woff2" crossOrigin="anonymous" type="font/woff2" />
      </Helmet>
      {children}
    </div>
  )
}
