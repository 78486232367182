import React from "react"
import cx from 'classnames'
import { Link } from "gatsby"

import * as styles from './Navigation.module.css'

export function Navigation ({ expanded, onExpandedChange}) {
  return (
    <nav className={cx(styles.navigation, expanded && styles.navigationIsExpanded)} role="navigation">
      <button type="button" onClick={() => { onExpandedChange(!expanded) }} className={styles.closeButton}>
        <span className={cx('u-screenreader-text')}>Sluit menu</span>
      </button>
      <Link to="/" className={styles.link} activeClassName={styles.linkActive}>Home</Link>
      <Link to="/praktijk" className={styles.link} activeClassName={styles.linkActive}>De praktijk</Link>
      <Link to="/behandelingen" className={styles.link} activeClassName={styles.linkActive}>Behandelingen</Link>
      <Link to="/mondhygieniste" className={styles.link} activeClassName={styles.linkActive}>De Mondhygiëniste</Link>
      <Link to="/contact" className={styles.link} activeClassName={styles.linkActive}>Contact</Link>
    </nav>
  )
}
